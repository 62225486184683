import { FeedbackContext } from "components/Feedback";
import { replace } from "connected-react-router";
import { api } from "core";
import {
  selectSchool as selectUserSchool,
  tokenAcquired
} from "core/user.actions";

export const typeUsername = username => ({
  type: "login.usernamechanged",
  payload: username
});

export const typePassword = password => ({
  type: "login.passwordchanged",
  payload: password
});

export const loginSsoGoogle = () => {
  return api.loginSsoGoogle();
};

const processLoginResponse = (loginResponse, dispatch) => {
  dispatch(
    tokenAcquired(
      loginResponse.data.access_token,
      loginResponse.data.refresh_token
    )
  );

  return api.getSchoolList().then(response => {
    if (response.data.length === 1) {
      dispatch(selectSchool(response.data[0].id));
    } else {
      dispatch({
        type: "login.schoolsLoaded",
        payload: response.data
      });
    }
  });
};

export const callbackSso = (code) => async (dispatch) => {
  try {
    const loginResponse = await api.callbackSso(code);
    return processLoginResponse(loginResponse, dispatch);
  } catch (error) {
    if (error?.response?.data?.error === "Conta google não vinculada.") {
      FeedbackContext.addFeedback(
        error?.response?.data?.error,
        "error"
      );
    }
  }
};

export const login = (username, password) => dispatch => {
  dispatch({
    type: "login.errorMessage",
    payload: ""
  });

  if (!username || !password || username.trim() === "" || password.trim() === "") {
    FeedbackContext.addFeedback(
      `Preencha os campos de usuário e senha.`,
      "error"
    );
    return dispatch({
      type: "login.errorMessage",
      payload: "Preencha os campos de usuário e senha."
    });
  }

  return api.loginSso(username, password)
    .then(loginResponse => {
      return processLoginResponse(loginResponse, dispatch);
    })
    .catch(error => {
      if (error && error.response && error.response.status === 401) {
        FeedbackContext.addFeedback(`Login ou senha inválidos.`, "error");
        return dispatch({
          type: "login.errorMessage",
          payload: "Login ou senha inválidos."
        });
      }
    });
};

export const selectSchool = schoolId => (dispatch, getState) => {
  dispatch(selectUserSchool(schoolId));
  dispatch(replace(getState().view.login.backTo));
};
